<template>
    <div class="add-new-customer">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.userUpdate')"
            :title="$t('messages.userUpdate')"
            @submitPressed="submitPressed"
        />
        <user-form
            v-if="user"
            :userObject="user"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import UserForm from "./components/UserForm.vue";
import {getUserData} from "@/auth/utils"

export default {
    components: {
        TitleBar,
        UserForm,
    },
    data() {
        return {
            user: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.$Users.getResource({id: getUserData().id}).then(response => {
                this.user = response.data
            })
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
